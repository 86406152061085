<template>
    <div>
        <div v-if="isexaminationdetailsfreezed == false" cols="12" sm="2" align="center">
            <span class="blink" style="font-size:16px;font-weight:bold;color:red;">You have not yet Freezed the
                Examination Details,Kindly freeze it.(If you have already done it before , Please do it once
                again)</span>
        </div>
        <div v-else>
            <span style="font-size:16px;font-weight:bold;color:green;"> Examination Details Freezed.</span>
        </div>
        <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
            <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
        <v-toolbar dense class="tc-title">
            <router-link to="student-academicsdynamic" class="nav-arrow-left"><v-icon
                    class="left-arrow">mdi-arrow-left-bold</v-icon></router-link>
            <v-toolbar-title>Examination Details </v-toolbar-title>
            <v-spacer></v-spacer>
            <template>
                <span v-if="iserpapplicable == true">
                    <v-btn color="primary" class="logi mx-2" small dark @click="synchvierp()">Fetch Examination Details
                        From ERP</v-btn>
                </span>
                <v-btn color="primary" class="logi mx-2" fab small dark
                    @click="dialog_add = true"><v-icon>mdi-plus</v-icon></v-btn>
                <router-link to="student-cvdynamic" class="nav-arrow-right"><v-icon
                        class="right-arrow">mdi-forward</v-icon></router-link>
                <a class="blink" href="https://www.youtube.com/watch?v=w7z-uch9Be4" target="_blank"><span
                        style="color:red;font-size:35px;" class="mdi mdi-message-video"></span></a>
            </template>
        </v-toolbar>
        <!--- <div v-if="academics_list">
        <v-row>
        <v-col cols="12" sm="4" v-for="item in academics_list" :key="item.id">
          <v-card style=" margin: 8px; height:100%" >
            <v-card-title class="c-title">
              {{ item.degree }}
            </v-card-title>
            <v-card-text>
              <v-row style="margin: 0px !important; padding: 0px !important">
                <v-col cols="6" sm="5" style="margin: 0px !important; padding: 0px !important">
                  <span class="lbl">Education</span>
                </v-col>
                <v-col cols="6" md="4" style="margin: 0px !important; padding: 0px !important"> : {{ item.degree }} </v-col>
              </v-row>
              <v-row style="margin: 0px !important; padding: 0px !important">
                <v-col cols="6" sm="5" style="margin: 0px !important; padding: 0px !important">
                  <span class="lbl">University</span>
                </v-col>
                <v-col cols="6" md="6" style="margin: 0px !important; padding: 0px !important"> : {{ item.university }} </v-col>
              </v-row>
              <v-row style="margin: 0px !important; padding: 0px !important">
                <v-col cols="6" sm="5" style="margin: 0px !important; padding: 0px !important">
                  <span class="lbl">Institute Name</span>
                </v-col>
                <v-col cols="6" md="6" style="margin: 0px !important; padding: 0px !important"> : {{ item.name_of_institute }} </v-col>
              </v-row>
              <v-row style="margin: 0px !important; padding: 0px !important">
                <v-col cols="6" sm="5" style="margin: 0px !important; padding: 0px !important">
                  <span class="lbl">{{item.type}}</span>
                </v-col>
                <v-col cols="6" md="6" style="margin: 0px !important; padding: 0px !important"> : {{ item.cpi_marks }} </v-col>
              </v-row>-->
        <!--  <v-row style="margin: 0px !important; padding: 0px !important">
                <v-col cols="6" sm="5" style="margin: 0px !important; padding: 0px !important">
                  <span class="lbl">Class</span>
                </v-col>
                <v-col cols="6" md="6" style="margin: 0px !important; padding: 0px !important"> : {{ item.resultclass }} </v-col>
              </v-row>-->
        <!----  <v-row style="margin: 0px !important; padding: 0px !important">
                <v-col cols="6" sm="5" style="margin: 0px !important; padding: 0px !important">
                  <span class="lbl">Passing Year</span>
                </v-col>
                <v-col cols="6" md="6" style="margin: 0px !important; padding: 0px !important"> : {{ item.yearofpassing }} </v-col>
              </v-row>
            </v-card-text>-->
        <!-- <v-card-actions>
              <v-icon color="indigo" @click="editItem(item)"
                >mdi-pencil-circle</v-icon
              >-->
        <!--- <v-spacer></v-spacer>
              <v-icon color="red" @click="delId(item)">mdi-delete-circle</v-icon>
            </v-card-actions>
          </v-card>
        </v-col>
        </v-row>
      </div> 
      <h1 v-else>NO DATA</h1> -->


        <template>

            <!---- <v-data-table :headers="headers" :items="academics_list" class="elevation-1" :search="search">
                  <template v-slot:item.edit="{ item }">
                           <v-icon color="indigo" @click="editItem(item)">mdi-pencil-circle</v-icon>
                  </template>
<template v-slot:item.delete="{ item }">
                      <v-icon color="red" @click="delId(item)">mdi-delete-circle</v-icon>
                  </template>
</v-data-table>-->

            <v-row class="mt-5 ml-2 mr-2" cols="12" sm="12">
                <v-card style="width: 100%;">
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" sm="5" v-if="isexaminationdetailsfreezed == false && Current_Degree_Latest_CGPA_CPI_Dynamic.isActive">
                                <span v-if="Current_Degree_Latest_CGPA_CPI_Dynamic.isEditable">
                                    <span v-if="Current_Degree_Latest_CGPA_CPI_Dynamic.isRequired">
                                        <v-select v-model="selected_cgpa" :items="current_inst_aca_list" item-text="cgpa_name"
                                        item-value="cgpa_id" @change="isOnchangeCurrentCPI()" outlined dense>
                                        <template #label>
                                            <span class="ml-1 mb-2">{{ Current_Degree_Latest_CGPA_CPI_Dynamic.display_name }}<span v-if="Current_Degree_Latest_CGPA_CPI_Dynamic.isRequired"><small style="color: red"> *</small></span> </span>
                                            </template>
                                        </v-select>
                                    </span>
                                    <span v-else>
                                        <v-select v-model="selected_cgpa" :items="current_inst_aca_list" item-text="cgpa_name"
                                        item-value="cgpa_id" @change="isOnchangeCurrentCPI()" outlined dense>
                                        <template #label>
                                            <span class="ml-1 mb-2">{{ Current_Degree_Latest_CGPA_CPI_Dynamic.display_name }}<span v-if="Current_Degree_Latest_CGPA_CPI_Dynamic.isRequired"><small style="color: red"> *</small></span> </span>
                                            </template>
                                        </v-select>
                                    </span>
                                </span>
                                <span v-else>
                                    <v-select v-model="selected_cgpa" disabled :items="current_inst_aca_list" item-text="cgpa_name"
                                    item-value="cgpa_id" @change="isOnchangeCurrentCPI()" outlined dense>
                                    <template #label>
                                        <span class="ml-1 mb-2">{{ Current_Degree_Latest_CGPA_CPI_Dynamic.display_name }}<span v-if="Current_Degree_Latest_CGPA_CPI_Dynamic.isRequired"><small style="color: red"> *</small></span> </span>
                                        </template>
                                    </v-select>
                                </span>
                            </v-col>
                            <v-col cols="12" sm="5" v-else>
                                <v-text-field v-model="selected_cgpaname" disabled outlined dense>
                                    <template #label>
                                        <span class="ml-1 mb-2">{{ Current_Degree_Latest_CGPA_CPI_Dynamic.display_name }}<span v-if="Current_Degree_Latest_CGPA_CPI_Dynamic.isRequired"><small style="color: red"> *</small></span> </span>
                                        </template>
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="3" style="margin-top:20px;" align="center">
                                <label v-if="Array.isArray(deadbacklogs) &&
                deadbacklogs.length"><b>No. Of. Dead Backlogs :</b> {{ deadbacklogs }}<br></label>
                <label v-else><b>No. Of. Dead Backlogs :</b> <br></label>
                            </v-col>
                            <v-col cols="12" sm="3" style="margin-top:20px;" align="center">
                                <label v-if="Array.isArray(deadbacklogs) &&
                deadbacklogs.length"><b>No. Of. Active Backlogs : </b>{{ activebacklogs }}<br><br></label>
                <label v-else><b>No. Of. Active Backlogs : </b> <br><br></label>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-row><br>
            <v-div class="mt-5 mb-5"></v-div>

            <div v-if="Array.isArray(academics_list) &&
                academics_list.length">
                <v-data-table :headers="headers" :items="academics_list" :search="search">
                    <template v-slot:item.action="{ item }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ }">
                                <v-avatar v-if="isexaminationdetailsfreezed == false" style size="25"
                                    class="edit-avatar">
                                    <v-icon class="edit-v-icon mr-2" @click="editItem(item)">edit</v-icon>
                                </v-avatar>
                                <v-avatar v-else style size="25">
                                    -
                                </v-avatar>
                            </template>
                            <span>Edit</span>
                        </v-tooltip>
                        <v-btn v-if="isexaminationdetailsfreezed == false" text>
                            <v-icon color="red" @click="delId(item.id)">
                                mdi-delete</v-icon></v-btn>
                    </template>
                </v-data-table>

                <template>
                    <v-row justify="center">
                        <v-col cols="15" sm="1" align="center"></v-col>
                        <v-col v-if="isexaminationdetailsfreezed == false" cols="12" md="11" align="center"><br>
                            <p class="blink" style="font-size:20px;font-weight:bold;"> NOTE : Please Enter All Semester
                                CPI/SPI Marks. </p><br>
                            <span class="blink" style="font-size:18px;font-weight:bold;"> NOTE : IF you are direct
                                second year admission Please go to personal details mark checkbox - Is Direct Second
                                Year Admission and again save and freeze personal details.</span><br>
                            <span style="font-size:16px;font-weight:bold;">NOTE : Freeze the details.</span><br>
                            <v-btn style="margin-top:20px" color="primary darken-1" dark
                                @click="freezexamination()">Freeze All</v-btn>
                        </v-col>
                        <v-col v-else cols="12" sm="4" align="center"><br>
                            <span style="font-size:16px;font-weight:bold;color:red;">You Have Already Freezed the
                                Details.</span>
                            <v-btn style="margin-top:20px" color="primary darken-1" dark
                                @click="unfreezeStudentdetails('examination')">Unfreeze</v-btn>
                        </v-col>
                    </v-row>
                </template>




            </div>
        </template>
        <template>
            <v-row justify="center">
                <v-dialog v-model="dialog_add" persistent max-width="800px">
                    <v-card>
                        <v-card-title class="card-title"> Add Current Institute Academic Details</v-card-title>
                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col cols="12" sm="6" v-if="Program_Dynamic && Program_Dynamic.isActive">
                                        <!-- <v-text-field
                                            v-model="program"
                                            readonly  :rules="[v => !!v || 'required']"
                                        ></v-text-field> -->
                                        <span v-if="Program_Dynamic.isEditable">
                                            <span v-if="Program_Dynamic.isRequired">
                                                <v-select :items="program_list" v-model="savedata.program" outlined
                                                    item-text="program_name" item-value="program_id" required
                                                    :rules="[v => !!v || 'required']">
                                                    <template #label>
                                                        <span class="ml-1 mb-2">{{ Program_Dynamic.display_name }}<small style="color: red"> *</small>  </span>
                                                    </template>
                                                </v-select>
                                            </span>
                                            <span v-else>
                                                <v-select :items="program_list" v-model="savedata.program" outlined
                                                    item-text="program_name" item-value="program_id">
                                                    <template #label>
                                                        <span class="ml-1 mb-2">{{ Program_Dynamic.display_name }}<small style="color: red"></small>  </span>
                                                    </template>
                                                </v-select>
                                            </span>
                                        </span>
                                        <span v-else>
                                            <v-select :items="program_list" v-model="savedata.program" outlined disabled
                                                item-text="program_name" item-value="program_id">
                                                <template #label>
                                                    <span class="ml-1 mb-2">{{ Program_Dynamic.display_name }}<small style="color: red"></small>  </span>
                                                </template>
                                            </v-select>
                                        </span>
                                    </v-col>
                                    <v-col cols="12" sm="6" v-if="Year_Dynamic && Year_Dynamic.isActive">
                                        <span v-if="Year_Dynamic.isRequired">
                                            <v-select :items="year_List" v-model="savedata.year" item-text="name" outlined
                                            item-value="id" required :rules="[v => !!v || 'required']">
                                            <template #label>
                                                <span class="ml-1 mb-2">{{ Year_Dynamic.display_name }}<small style="color: red"> *</small>  </span>
                                            </template>
                                            </v-select>
                                        </span>
                                        <span v-else>
                                            <v-select :items="year_List" v-model="savedata.year" item-text="name" outlined
                                            item-value="id">
                                            <template #label>
                                                <span class="ml-1 mb-2">{{ Year_Dynamic.display_name }}<small style="color: red"></small>  </span>
                                            </template>
                                            </v-select>
                                        </span>
                                    </v-col>
                                    <v-col cols="12" sm="6" v-if="Academic_Year_Dynamic && Academic_Year_Dynamic.isActive">
                                        <span v-if="Academic_Year_Dynamic.isRequired">
                                            <v-select :items="ay_List" v-model="savedata.ay" item-text="ay" item-value="id" outlined
                                            required :rules="[v => !!v || 'required']">
                                            <template #label>
                                                <span class="ml-1 mb-2">{{ Academic_Year_Dynamic.display_name }}<small style="color: red"> *</small>  </span>
                                            </template>
                                            </v-select>
                                        </span>
                                        <span v-else>
                                            <v-select :items="ay_List" v-model="savedata.ay" item-text="ay" item-value="id" outlined>
                                            <template #label>
                                                <span class="ml-1 mb-2">{{ Academic_Year_Dynamic.display_name }}<small style="color: red"> </small>  </span>
                                            </template>
                                            </v-select>
                                        </span>
                                    </v-col>

                                    <v-col cols="12" sm="6" v-if="Sem_Dynamic && Sem_Dynamic.isActive">
                                        <span v-if="Sem_Dynamic.isRequired">
                                            <v-select :items="sem_List" v-model="savedata.sem" item-text="sem" outlined
                                            item-value="id" required :rules="[v => !!v || 'required']">
                                            <template #label>
                                                <span class="ml-1 mb-2">{{ Sem_Dynamic.display_name }}<small style="color: red"> *</small>  </span>
                                            </template>
                                        </v-select>
                                        </span>
                                        <span v-else>
                                            <v-select :items="sem_List" v-model="savedata.sem" item-text="sem"
                                            item-value="id" outlined>
                                            <template #label>
                                                <span class="ml-1 mb-2">{{ Sem_Dynamic.display_name }}<small style="color: red"> </small>  </span>
                                            </template>
                                        </v-select>
                                        </span>
                                    </v-col>
                                    <v-col cols="12" sm="6" v-if="CPI_Dynamic && CPI_Dynamic.isActive">
                                        <span v-if="CPI_Dynamic.isRequired">
                                            <v-text-field type="number" v-model="savedata.percentage_cpi" outlined
                                            hint="Only Enter Number Value" :rules="[v => !!v || 'required']" :value="0"
                                            min="0">
                                            <template #label>
                                                <span class="ml-1 mb-2">{{ CPI_Dynamic.display_name }}<small style="color: red"> *</small>  </span>
                                            </template>
                                            </v-text-field>
                                        </span>
                                        <span v-else>
                                            <v-text-field type="number" v-model="savedata.percentage_cpi" outlined
                                            hint="Only Enter Number Value" :value="0" min="0">
                                            <template #label>
                                                <span class="ml-1 mb-2">{{ CPI_Dynamic.display_name }}<small style="color: red"> </small>  </span>
                                            </template>
                                            </v-text-field>
                                        </span>
                                    </v-col>
                                    <v-col cols="12" sm="6" v-if="SPI_Dynamic && SPI_Dynamic.isActive">
                                        <span v-if="SPI_Dynamic.isRequired">
                                            <v-text-field type="number" v-model="savedata.spi_marks" outlined
                                            hint="Only Enter Number Value" :rules="[v => !!v || 'required']" :value="0"
                                            min="0">
                                            <template #label>
                                                <span class="ml-1 mb-2">{{ SPI_Dynamic.display_name }}<small style="color: red"> *</small>  </span>
                                            </template>
                                            </v-text-field>
                                        </span>
                                        <span v-else>
                                            <v-text-field type="number" v-model="savedata.spi_marks"
                                            hint="Only Enter Number Value" outlined :value="0"
                                            min="0">
                                            <template #label>
                                                <span class="ml-1 mb-2">{{ SPI_Dynamic.display_name }}<small style="color: red"> </small>  </span>
                                            </template>
                                            </v-text-field>
                                        </span>
                                    </v-col>
                                    <v-col cols="12" sm="4" v-if="No_Of_Dead_Backlog_Dynamic && No_Of_Dead_Backlog_Dynamic.isActive">
                                        <span v-if="No_Of_Dead_Backlog_Dynamic.isRequired">
                                            <v-text-field type="number" v-model="savedata.no_of_deadbacklog" outlined
                                            hint="Only Enter Number Value" :rules="[v => !!v || 'required']" :value="0"
                                            min="0">
                                            <template #label>
                                                <span class="ml-1 mb-2">{{ No_Of_Dead_Backlog_Dynamic.display_name }}<small style="color: red"> *</small>  </span>
                                            </template>
                                            </v-text-field>
                                        </span>
                                        <span v-else>
                                            <v-text-field type="number" v-model="savedata.no_of_deadbacklog" outlined
                                            hint="Only Enter Number Value" :value="0" min="0">
                                            <template #label>
                                                <span class="ml-1 mb-2">{{ No_Of_Dead_Backlog_Dynamic.display_name }}<small style="color: red"> </small>  </span>
                                            </template>
                                            </v-text-field>
                                        </span>
                                    </v-col>
                                    <v-col cols="12" sm="4" v-if="isLiveBackLog_Dynamic && isLiveBackLog_Dynamic.isActive">
                                        <h4><input type="checkbox" v-model="savedata.islivebacklog" color="success"
                                                style="margin: 0.4rem; width:18px; height: 15px;" />&nbsp;&nbsp; {{ isLiveBackLog_Dynamic.display_name }}</h4>
                                    </v-col>
                                    <v-col cols="12" sm="4" v-if="savedata.islivebacklog && No_Of_Active_Backlog_Dynamic.isActive">
                                        <span v-if="No_Of_Active_Backlog_Dynamic.isRequired">
                                            <v-text-field type="number" v-model="savedata.no_of_activebacklog" outlined
                                            hint="Only Enter Number Value" :rules="[v => !!v || 'required']" :value="0"
                                            min="0">
                                            <template #label>
                                                <span class="ml-1 mb-2">{{ No_Of_Active_Backlog_Dynamic.display_name }}<small style="color: red"> *</small>  </span>
                                            </template>
                                            </v-text-field>
                                        </span>
                                        <span v-else>
                                            <v-text-field type="number" v-model="savedata.no_of_activebacklog"
                                            hint="Only Enter Number Value" :value="0" outlined min="0">
                                            <template #label>
                                                <span class="ml-1 mb-2">{{ No_Of_Active_Backlog_Dynamic.display_name }}<small style="color: red"> </small>  </span>
                                            </template>
                                            </v-text-field>
                                        </span>
                                    </v-col>
                                    <v-col cols="12" sm="4" v-if="Backlog_Name_Dynamic && Backlog_Name_Dynamic.isActive">
                                        <span v-if="Backlog_Name_Dynamic.isRequired">
                                            <v-text-field v-model="savedata.backlog_name" outlined
                                            :rules="[v => !!v || 'required']">
                                            <template #label>
                                                <span class="ml-1 mb-2">{{ Backlog_Name_Dynamic.display_name }}<small style="color: red"> *</small>  </span>
                                            </template>
                                            </v-text-field>
                                        </span>
                                        <span v-else>
                                            <v-text-field v-model="savedata.backlog_name" outlined>
                                            <template #label>
                                                <span class="ml-1 mb-2">{{ Backlog_Name_Dynamic.display_name }}<small style="color: red"> </small>  </span>
                                            </template>
                                            </v-text-field>
                                        </span>
                                    </v-col>
                                </v-row>
                                <v-row v-if="Acknowledge_Checkbox_For_Examination.isActive">
                                    <h4><input type="checkbox" v-model="savedata.isackowledge" color="success"
                                            style="margin: 0.4rem;" />&nbsp;&nbsp;{{ Acknowledge_Checkbox_For_Examination.display_name }}</h4>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary darken-1" outlined text @click="dialog_add = false">Cancel</v-btn>
                            <v-btn color="primary darken-1" dark @click="save()">Save</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-row>
        </template>
        <template>
            <v-row justify="center">
                <v-dialog v-model="dialog_edit" persistent max-width="800px">
                    <v-card>
                        <v-card-title class="card-title"> Edit Academic Details</v-card-title>
                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col cols="12" sm="6" v-if="Program_Dynamic.isActive">
                                       <span v-if="Program_Dynamic.isEditable">
                                        <!-- <v-text-field v-model="program" readonly
                                            :rules="[v => !!v || 'required']"></v-text-field> -->
                                            <span v-if="Program_Dynamic.isRequired">
                                                <v-select :items="program_list" v-model="editedItem.program" outlined item-text="program_name"
                                                item-value="program_id" required :rules="[v => !!v || 'required']">
                                                <template #label>
                                                <span class="ml-1 mb-2">{{ Program_Dynamic.display_name }}<small style="color: red"> *</small>  </span>
                                                </template>
                                                </v-select>
                                            </span>
                                            <span v-else>
                                                <v-select :items="program_list" v-model="editedItem.program" item-text="program_name"
                                                item-value="program_id" outlined>
                                                <template #label>
                                                <span class="ml-1 mb-2">{{ Program_Dynamic.display_name }}<small style="color: red"> </small>  </span>
                                                </template>
                                                </v-select>
                                            </span>
                                       </span>
                                       <span v-else>
                                            <v-select disabled :items="program_list" v-model="editedItem.program" item-text="program_name"
                                            item-value="program_id" outlined>
                                            <template #label>
                                            <span class="ml-1 mb-2">{{ Program_Dynamic.display_name }}<span v-if="Program_Dynamic.isRequired"><small style="color: red"> *</small></span> </span>
                                            </template>
                                            </v-select>
                                       </span>
                                    </v-col>
                                    <v-col cols="12" sm="6" v-if="Year_Dynamic.isActive">
                                        <span v-if=Year_Dynamic.isEditable>
                                            <span v-if="Year_Dynamic.isRequired">
                                                <v-select :items="year_List" v-model="editedItem.year" item-text="name"
                                                item-value="id" required :rules="[v => !!v || 'required']" outlined>
                                                <template #label>
                                                <span class="ml-1 mb-2">{{ Year_Dynamic.display_name }}<span v-if="Year_Dynamic.isRequired"><small style="color: red"> *</small></span> </span>
                                                </template>
                                                </v-select>
                                            </span>
                                            <span v-else>
                                                <v-select :items="year_List" v-model="editedItem.year" item-text="name"
                                                item-value="id" outlined>
                                                <template #label>
                                                <span class="ml-1 mb-2">{{ Year_Dynamic.display_name }}<span v-if="Year_Dynamic.isRequired"><small style="color: red"> *</small></span> </span>
                                                </template>
                                                </v-select>
                                            </span>
                                        </span>
                                        <span v-else>
                                            <v-select disabled :items="year_List" v-model="editedItem.year" item-text="name"
                                            item-value="id" outlined>
                                            <template #label>
                                            <span class="ml-1 mb-2">{{ Year_Dynamic.display_name }}<span v-if="Year_Dynamic.isRequired"><small style="color: red"> *</small></span> </span>
                                            </template>
                                            </v-select>
                                        </span>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" sm="6" v-if="Academic_Year_Dynamic.isActive">
                                        <span v-if="Academic_Year_Dynamic.isEditable">
                                            <span v-if="Academic_Year_Dynamic.isRequired">
                                                <v-select :items="ay_List" v-model="editedItem.ay" item-text="ay"
                                                item-value="id" required :rules="[v => !!v || 'required']" outlined>
                                                <template #label>
                                                <span class="ml-1 mb-2">{{ Academic_Year_Dynamic.display_name }}<span v-if="Academic_Year_Dynamic.isRequired"><small style="color: red"> *</small></span> </span>
                                                </template>
                                                </v-select>
                                            </span>
                                            <span v-else>
                                                <v-select :items="ay_List" v-model="editedItem.ay" item-text="ay"
                                                item-value="id" outlined>
                                                <template #label>
                                                <span class="ml-1 mb-2">{{ Academic_Year_Dynamic.display_name }}<span v-if="Academic_Year_Dynamic.isRequired"><small style="color: red"> *</small></span> </span>
                                                </template>
                                                </v-select>
                                            </span>
                                        </span>
                                        <span v-else>
                                            <v-select disabled :items="ay_List" v-model="editedItem.ay" item-text="ay"
                                                item-value="id" outlined>
                                                <template #label>
                                                <span class="ml-1 mb-2">{{ Academic_Year_Dynamic.display_name }}<span v-if="Academic_Year_Dynamic.isRequired"><small style="color: red"> *</small></span> </span>
                                                </template>
                                                </v-select>
                                        </span>
                                    </v-col>

                                    <v-col cols="12" sm="6" v-if="Sem_Dynamic.isActive">
                                        <span v-if="Sem_Dynamic.isEditable">
                                            <span v-if="Sem_Dynamic.isRequired">
                                                <v-select :items="sem_List" v-model="editedItem.sem" item-text="sem"
                                                item-value="id" required :rules="[v => !!v || 'required']" outlined>
                                                <template #label>
                                                <span class="ml-1 mb-2">{{ Sem_Dynamic.display_name }}<span v-if="Sem_Dynamic.isRequired"><small style="color: red"> *</small></span> </span>
                                                </template>
                                                </v-select>
                                            </span>
                                            <span v-else>
                                                <v-select :items="sem_List" v-model="editedItem.sem" item-text="sem"
                                                item-value="id" outlined>
                                                <template #label>
                                                <span class="ml-1 mb-2">{{ Sem_Dynamic.display_name }}<span v-if="Sem_Dynamic.isRequired"><small style="color: red"> *</small></span> </span>
                                                </template>
                                                </v-select>
                                            </span>
                                        </span>
                                        <span v-else>
                                            <v-select disabled :items="sem_List" v-model="editedItem.sem" item-text="sem"
                                            item-value="id" outlined>
                                            <template #label>
                                            <span class="ml-1 mb-2">{{ Sem_Dynamic.display_name }}<span v-if="Sem_Dynamic.isRequired"><small style="color: red"> *</small></span> </span>
                                            </template>
                                            </v-select>
                                        </span>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" sm="6" v-if="CPI_Dynamic.isActive">
                                        <span v-if="CPI_Dynamic.isEditable">
                                            <span v-if="CPI_Dynamic.isRequired">
                                                <v-text-field type="number" v-model="editedItem.percentage_cpi" outlined
                                                hint="Only Enter Number Value" :rules="[v => !!v || 'required']" :value="0"
                                                min="0">
                                                <template #label>
                                                <span class="ml-1 mb-2">{{ CPI_Dynamic.display_name }}<span v-if="CPI_Dynamic.isRequired"><small style="color: red"> *</small></span> </span>
                                                </template>
                                                </v-text-field>
                                            </span>
                                            <span v-else>
                                                <v-text-field type="number" v-model="editedItem.percentage_cpi"
                                                hint="Only Enter Number Value" :value="0" min="0" outlined>
                                                <template #label>
                                                <span class="ml-1 mb-2">{{ CPI_Dynamic.display_name }}<span v-if="CPI_Dynamic.isRequired"><small style="color: red"> *</small></span> </span>
                                                </template>
                                                </v-text-field>
                                            </span>
                                        </span>
                                        <span v-else>
                                            <v-text-field disabled type="number" v-model="editedItem.percentage_cpi"
                                            hint="Only Enter Number Value" :value="0" min="0" outlined>
                                            <template #label>
                                            <span class="ml-1 mb-2">{{ CPI_Dynamic.display_name }}<span v-if="CPI_Dynamic.isRequired"><small style="color: red"> *</small></span> </span>
                                            </template>
                                            </v-text-field>
                                        </span>
                                    </v-col>
                                    <v-col cols="12" sm="6" v-if="SPI_Dynamic.isActive">
                                        <span v-if="SPI_Dynamic.isEditable">
                                            <span v-if="SPI_Dynamic.isRequired">
                                                <v-text-field type="number" v-model="editedItem.spi_marks" outlined
                                                hint="Only Enter Number Value" :rules="[v => !!v || 'required']" :value="0"
                                                min="0">
                                                <template #label>
                                                <span class="ml-1 mb-2">{{ SPI_Dynamic.display_name }}<span v-if="SPI_Dynamic.isRequired"><small style="color: red"> *</small></span> </span>
                                                </template>
                                                </v-text-field>
                                            </span>
                                            <span v-else>
                                                <v-text-field type="number" v-model="editedItem.spi_marks"
                                                hint="Only Enter Number Value" outlined :value="0"
                                                min="0">
                                                <template #label>
                                                <span class="ml-1 mb-2">{{ SPI_Dynamic.display_name }}<span v-if="SPI_Dynamic.isRequired"><small style="color: red"> *</small></span> </span>
                                                </template>
                                                </v-text-field>
                                            </span>
                                        </span>
                                        <span v-else>
                                            <v-text-field disabled type="number" v-model="editedItem.spi_marks"
                                            hint="Only Enter Number Value" outlined :value="0"
                                            min="0">
                                            <template #label>
                                            <span class="ml-1 mb-2">{{ SPI_Dynamic.display_name }}<span v-if="SPI_Dynamic.isRequired"><small style="color: red"> *</small></span> </span>
                                            </template>
                                            </v-text-field>
                                        </span>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" sm="4" v-if="No_Of_Dead_Backlog_Dynamic.isActive">
                                        <span v-if="No_Of_Dead_Backlog_Dynamic.isEditable">
                                            <span v-if="No_Of_Dead_Backlog_Dynamic.isRequired">
                                                <v-text-field type="number" v-model="editedItem.no_of_deadbacklog" outlined
                                                hint="Only Enter Number Value" :rules="[v => !!v || 'required']" :value="0"
                                                min="0">
                                                <template #label>
                                                <span class="ml-1 mb-2">{{ No_Of_Dead_Backlog_Dynamic.display_name }}<span v-if="No_Of_Dead_Backlog_Dynamic.isRequired"><small style="color: red"> *</small></span> </span>
                                                </template>
                                                </v-text-field>
                                            </span>
                                            <span v-else>
                                                <v-text-field type="number" v-model="editedItem.no_of_deadbacklog"
                                                hint="Only Enter Number Value" outlined :value="0" min="0">
                                                <template #label>
                                                <span class="ml-1 mb-2">{{ No_Of_Dead_Backlog_Dynamic.display_name }}<span v-if="No_Of_Dead_Backlog_Dynamic.isRequired"><small style="color: red"> *</small></span> </span>
                                                </template>
                                                </v-text-field>
                                            </span>
                                        </span>
                                        <span v-else>
                                            <v-text-field disabled type="number" v-model="editedItem.no_of_deadbacklog"
                                            hint="Only Enter Number Value" outlined :value="0" min="0">
                                            <template #label>
                                            <span class="ml-1 mb-2">{{ No_Of_Dead_Backlog_Dynamic.display_name }}<span v-if="No_Of_Dead_Backlog_Dynamic.isRequired"><small style="color: red"> *</small></span> </span>
                                            </template>
                                            </v-text-field>
                                        </span>
                                    </v-col>
                                    <v-col cols="12" sm="4" v-if="isLiveBackLog_Dynamic.isActive">
                                        <h4><input type="checkbox" v-model="editedItem.islivebacklog" color="success"
                                                style="margin: 0.4rem; width:18px; height: 15px;" />&nbsp;&nbsp; {{isLiveBackLog_Dynamic.display_name}}</h4>
                                    </v-col>
                                    <v-col cols="12" sm="4" v-if="editedItem.islivebacklog && No_Of_Active_Backlog_Dynamic.isActive">
                                        <span v-if="No_Of_Active_Backlog_Dynamic.isEditable">
                                            <span v-if="No_Of_Active_Backlog_Dynamic.isRequired">
                                                <v-text-field type="number" v-model="editedItem.no_of_activebacklog"
                                                hint="Only Enter Number Value" :rules="[v => !!v || 'required']" :value="0"
                                                min="0" outlined>
                                                <template #label>
                                                <span class="ml-1 mb-2">{{ No_Of_Active_Backlog_Dynamic.display_name }}<span v-if="No_Of_Active_Backlog_Dynamic.isRequired"><small style="color: red"> *</small></span> </span>
                                                </template>
                                                </v-text-field>
                                            </span>
                                            <span v-else>
                                                <v-text-field type="number" v-model="editedItem.no_of_activebacklog"
                                                hint="Only Enter Number Value" outlined :value="0" min="0">
                                                <template #label>
                                                <span class="ml-1 mb-2">{{ No_Of_Active_Backlog_Dynamic.display_name }}<span v-if="No_Of_Active_Backlog_Dynamic.isRequired"><small style="color: red"> *</small></span> </span>
                                                </template>
                                                </v-text-field>
                                            </span>
                                        </span>
                                        <span v-else>
                                            <v-text-field disabled type="number" v-model="editedItem.no_of_activebacklog"
                                            hint="Only Enter Number Value" outlined :value="0" min="0">
                                            <template #label>
                                            <span class="ml-1 mb-2">{{ No_Of_Active_Backlog_Dynamic.display_name }}<span v-if="No_Of_Active_Backlog_Dynamic.isRequired"><small style="color: red"> *</small></span> </span>
                                            </template>
                                            </v-text-field>
                                        </span>
                                    </v-col>
                                    <v-col cols="12" sm="4" v-if="Backlog_Name_Dynamic.isActive">
                                        <span v-if="Backlog_Name_Dynamic.isEditable">
                                            <span v-if="Backlog_Name_Dynamic.isRequired">
                                                <v-text-field v-model="editedItem.backlog_name" outlined :rules="[v => !!v || 'required']">
                                                <template #label>
                                                <span class="ml-1 mb-2">{{ Backlog_Name_Dynamic.display_name }}<span v-if="Backlog_Name_Dynamic.isRequired"><small style="color: red"> *</small></span> </span>
                                                </template>
                                                </v-text-field>
                                            </span>
                                            <span v-else>
                                                <v-text-field v-model="editedItem.backlog_name" outlined >
                                                <template #label>
                                                <span class="ml-1 mb-2">{{ Backlog_Name_Dynamic.display_name }}<span v-if="Backlog_Name_Dynamic.isRequired"><small style="color: red"> *</small></span> </span>
                                                </template>
                                                </v-text-field>
                                            </span>
                                        </span>
                                        <span v-else>
                                            <v-text-field disabled v-model="editedItem.backlog_name" outlined >
                                            <template #label>
                                            <span class="ml-1 mb-2">{{ Backlog_Name_Dynamic.display_name }}<span v-if="Backlog_Name_Dynamic.isRequired"><small style="color: red"> *</small></span> </span>
                                            </template>
                                            </v-text-field>
                                        </span>
                                    </v-col>
                                </v-row>
                                <v-row v-if="Acknowledge_Checkbox_For_Examination.isActive">
                                    <h4><input type="checkbox" v-model="editedItem.isackowledge" color="success"
                                            style="margin: 0.4rem; width:18px; height: 15px;" />&nbsp;&nbsp; {{Acknowledge_Checkbox_For_Examination.display_name}}</h4>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary darken-1" outlined text @click="dialog_edit = false">Cancel</v-btn>
                            <v-btn color="primary darken-1" dark @click="edit()">Save</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-row>
        </template>
        <template>
            <v-row justify="center">
                <v-dialog v-model="dialog_delete" persistent max-width="290">
                    <v-card>
                        <v-card-title class="del-card-title">Record Delete</v-card-title>
                        <input type="hidden" v-model="deleteItem.academic_id" />
                        <v-card-text>
                            Are you sure you want Delete this Record?
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="green darken-1" text @click="dialog_delete = false">No</v-btn>
                            <v-btn color="red darken-1" text @click="deletedata(deleteItem.academic_id)">Yes</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-row>
        </template>





        <br>
        <!--<template>
              <v-toolbar dense  class="tc-title">
                 <v-toolbar-title>Employability Details</v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-spacer></v-spacer>
                  <span style="width: 20px;"></span>
                  <template>
                      <v-btn color="primary" dark  class="logi"  @click="dialog_add_empb=true"><v-icon>mdi-plus</v-icon> Add Record</v-btn>
                  </template>
              </v-toolbar>
          </template>
          <template>
              <v-data-table :headers="headersnew" :items="academics_list" class="elevation-1" :search="search"> -->
        <!--<v-simple-table>
                  <template v-slot:default>
                      <thead>
                          <tr>
                              <th class="text-left">Sr. No.</th>
                              <th class="text-left">Academic Year</th>
                              <th class="text-left">Semester</th>
                              <th class="text-left">Test</th>
                              <th class="text-left">Score</th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr v-for="item in score_list" :key="item">
                              <td>{{ item.srno }}</td>
                              <td>{{ item.ay }}</td>
                              <td>{{ item.sem }}</td>
                              <td>{{ item.test_name }}</td>
                              <td>{{ item.score }}</td>
                          </tr>
                      </tbody>
                  </template>
              </v-simple-table>
              <v-row>
              <v-col cols="12" sm='4' v-for="item in score_list" :key="item">
                  <v-card>
                      <v-card-title class="ec-title">
                             {{item.test_name}}
                      </v-card-title>
                      <v-card-text>
                          <v-row>
                              <v-col cols="6" sm="4">
                                  <span class="lbl">Academic Year</span>
                              </v-col>
                              <v-col cols="6" md="6">
                                  : {{item.ay}}
                              </v-col>
                          </v-row>
                          <v-row>    
                              <v-col cols="6" sm="4">
                                  <span class="lbl">Semester</span>
                              </v-col>
                              <v-col cols="6" md="6">
                                  : {{item.sem}}
                              </v-col>
                          </v-row>
                          <v-row>    
                              <v-col cols="6" sm="4">
                                  <span class="lbl">Score</span>
                              </v-col>
                              <v-col cols="6" md="6">
                                  : {{item.score}}
                              </v-col>
                          </v-row> 
                      </v-card-text>
                  </v-card>
              </v-col>
           </v-row>
          </template>
          <template>
              <v-row justify="center">
                  <v-dialog v-model="dialog_add_empb" persistent max-width="550px">
                      <v-card>
                          <v-card-title class="card-title">
                              Add Employability Details
                          </v-card-title>
                          <v-card-text>
                              <v-container>
                                  <v-row>
                                      <v-col cols="12" sm="6">
                                          <v-select :items="ay_List" label="Academic Year" v-model="empb_savedata.ay" item-text="ay" item-value="id" required></v-select>
                                      </v-col>
                                      <v-col cols="12" sm="6">
                                          <v-select :items="sem_List" label="Semester" v-model="empb_savedata.sem" item-text="sem" item-value="id" required></v-select>
                                      </v-col>
                                  </v-row>
                                  <v-row>
                                      <v-col cols="12" sm="6">
                                          <v-select :items="test_List" label="Test" v-model="empb_savedata.test" item-text="test" item-value="id" required></v-select>
                                      </v-col>
                                      <v-col cols="12" sm="6">
                                          <v-text-field placeholder="Enter Marks" type="number" v-model="empb_savedata.score"></v-text-field>
                                      </v-col>
                                  </v-row>
                              </v-container>
                          </v-card-text>
                          <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="blue darken-1" text @click="dialog_add_empb= false">Close</v-btn>
                              <v-btn color="blue darken-1" text @click="testsave()">Save</v-btn>
                          </v-card-actions>
                      </v-card>
                  </v-dialog>
              </v-row>
          </template>-->
    </div>
</template>
<script>
import axios from "axios";
import StudentProfile from "./StudentProfile";
export default {
    components: {
        StudentProfile,
    },
    data: () => ({
        dialog_add_empb: false,

        savedata: {
            program: null,
            percentage_cpi: null,
            islivebacklog: null,
            ay: null,
            sem: null,
            year: null,
            spi_marks: null,
            no_of_activebacklog: null,
            no_of_deadbacklog: null,
            backlog_name: null,
        },
        dialog_add: false,
        dialog_edit: false,
        finalDegreeList: [],
        cpifieldname: "Percentage/CPI",
        dialog_delete: false,
        snackbar_msg: "",
        iserpapplicable: "",
        color: "",
        snackbar: false,
        search: "",
        academics_list: null,
        isexaminationdetailsfreezed: false,

        headers: [
            {
                text: "Sr. No",
                align: "left",
                sortable: false,
                value: "srno",
            },
            {
                text: "Year",
                align: "left",
                sortable: false,
                value: "year",
            },
            {
                text: "Academic Year",
                align: "left",
                sortable: false,
                value: "ay",
            },
            {
                text: "Semester",
                align: "left",
                sortable: false,
                value: "sem",
            },
            {
                text: "CPI",
                align: "left",
                sortable: false,
                value: "cpi_marks",
            },
            {
                text: "SPI",
                align: "left",
                sortable: false,
                value: "spi_marks",
            },
            {
                text: "Dead Backlog",
                align: "left",
                sortable: false,
                value: "no_of_deadbacklog",
            },
            {
                text: "Active Backlog",
                align: "left",
                sortable: false,
                value: "no_of_activebacklog",
            },
            {
                text: "Backlog Name",
                align: "left",
                sortable: false,
                value: "backlog_name",
            },
            { text: "Action", value: "action", sortable: false },
        ],
        editedItem: {
            program: null,
            percentage_cpi: null,
            islivebacklog: null,
            ay: null,
            sem: null,
            year: null,
            spi_marks: null,
            no_of_activebacklog: null,
            no_of_deadbacklog: null,
            backlog_name: null,
        },
        isexaminationdetailsfreezed: false,
        deleteItem: {
            academic_id: null,
        },
        editAllData: [],

        ay_List: null,
        sem_List: null,
        test_List: null,
        body: {
            email: "",
        },
        isdatafetched: false,
        academic_data: [],
        current_inst_aca_list: [],
        selected_cgpa: "",
        selected_cgpaname: [],
        selected_cgpa: [],
        deadbacklogs: [],
        activebacklogs: [],
        program_list: [],
        Program_Dynamic: "",
        Year_Dynamic:"",
        Academic_Year_Dynamic: "",
        Sem_Dynamic: "",
        CPI_Dynamic: "",
        SPI_Dynamic: "",
        No_Of_Dead_Backlog_Dynamic: "",
        isLiveBackLog_Dynamic: "",
        No_Of_Active_Backlog_Dynamic: "",
        Acknowledge_Checkbox_For_Examination: "",
        Current_Degree_Latest_CGPA_CPI_Dynamic: "",
        Backlog_Name_Dynamic: "",
        
    }),
    mounted() {
        this.onLoad();
    },
    methods: {
        onLoad() {
            axios
                .post("/LearnerData/learnerCurrentAcademics")
                .then((res) => {
                    if (res.data.msg == "200") {
                        this.academics_list = res.data.learner_academics;
                        this.savedata.program = res.data.program_id;
                        this.program_list = res.data.program_list;
                        this.ay_List = res.data.ay_List;
                        this.sem_List = res.data.sem_List;
                        this.year_List = res.data.year_List;
                        this.iserpapplicable = res.data.iserpapplicable;
                        this.isexaminationdetailsfreezed = res.data.isexaminationdetailsfreezed;
                        this.current_inst_aca_list = res.data.current_inst_aca_list;
                        this.selected_cgpa = res.data.cgpa_id;
                        this.selected_cgpaname = res.data.cgpa_name;
                        this.deadbacklogs = res.data.deadbacklogs;
                        this.activebacklogs = res.data.activebacklogs;
                        this.Program_Dynamic = res.data.Program;
                        this.Year_Dynamic = res.data.Year;
                        this.Academic_Year_Dynamic = res.data.Academic_Year;
                        this.Sem_Dynamic = res.data.Semester;
                        this.CPI_Dynamic = res.data.CPI_Marks;
                        this.SPI_Dynamic =res.data.SPI_Marks;
                        this.No_Of_Dead_Backlog_Dynamic = res.data.No_Of_Dead_Backlog;
                        this.isLiveBackLog_Dynamic = res.data.isLiveBackLog;
                        this.No_Of_Active_Backlog_Dynamic = res.data.No_Of_Active_Backlog;
                        this.Acknowledge_Checkbox_For_Examination = res.data.Acknowledge_Checkbox_For_Examination;
                        this.Current_Degree_Latest_CGPA_CPI_Dynamic = res.data.Current_Degree_Latest_CGPA_CPI;
                        this.Backlog_Name_Dynamic = res.data.Backlog_Name;
                    } else {
                        this.showSnackbar("#b71c1c", "No Data");
                    }
                })
                .catch((error) => {
                    this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
                    //window.console.log(error);
                })
                .finally(() => {
                    // 'use strict';
                });
        },
        showSnackbar(clr, msg) {
            this.snackbar = true;
            this.color = clr;
            this.snackbar_msg = msg;
        },

        unfreezeStudentdetails(item) {
            if (confirm("Are you sure, you want to unfreeze ?")) {
                const data = {
                    value: item,
                };
                axios
                    .post("/LearnerData/unfreezeStudentdetailsstudentside", data)
                    .then((res) => {
                        if (res.data.msg == "200") {
                            this.onLoad();
                            this.showSnackbar("#4caf50", "Details Unfreezed Successfully."); // show snackbar on success

                        } else {
                            // this.editAllData.splice(this.editAllData);
                            // this.showSnackbar("#b71c1c", "Record Already Present!!!");
                        }
                    })
                    .catch((error) => {
                        //window.console.log(error);
                    });
            }

        },
        editItem(item) {
            if (this.isexaminationdetailsfreezed == true) {
                this.showSnackbar("#b71c1c", "You Have freezed the Details");
                return;
            }
            //alert("ID "+item.resultclass_id)
            this.dialog_edit = true;
            this.editedItem.percentage_cpi = item.cpi_marks;
            this.editedItem.sem = item.semId;
            this.editedItem.ay = item.ayId;
            this.editedItem.year = item.yearId;
            this.editedItem.islivebacklog = item.islivebacklog;
            this.editedItem.id = item.id;
            this.editedItem.spi_marks = item.spi_marks;
            this.editedItem.no_of_activebacklog = item.no_of_activebacklog;
            this.editedItem.no_of_deadbacklog = item.no_of_deadbacklog;
            this.editedItem.program = item.prg_id;
            this.editedItem.backlog_name = item.backlog_name;

        },
        freezexamination() {
            if (confirm("Are you sure ?")) {
                axios
                    .post("/LearnerData/freezexamination")
                    .then((res) => {
                        if (res.data.code == "200") {
                            this.dialog_edit = false;
                            this.showSnackbar("#4caf50", "Details Freezed Successfully."); // show snackbar on success

                            this.isexaminationdetailsfreezed = res.data.isexaminationdetailsfreezed;

                        } else if (res.data.code == "INCOMPLETE") {
                            this.dialog_edit = false;
                            this.showSnackbar("#b71c1c", "Please fill every semester details you have completed."); // show snackbar on success

                            this.isexaminationdetailsfreezed = res.data.isexaminationdetailsfreezed;

                        } else {
                            // this.editAllData.splice(this.editAllData);
                            // this.showSnackbar("#b71c1c", "Record Already Present!!!");
                        }
                    })
                    .catch((error) => {
                        //window.console.log(error);
                    });
            }
        },
        close() {
            this.dialog = false;
            setTimeout(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            }, 300);
        },

        synchvierp() {
            this.isLoading = true
            axios
                .post("/AdminImport/syncexamination")
                .then((res) => {
                    if (res.data.status == "200") {
                        this.showSnackbar("#4caf50", "Examination Details Synchronized Successfully..!");
                        this.isdatafetched = true;
                        this.isLoading = false;
                    } else if (res.data.status == "404") {
                        this.showSnackbar("#b71c1c", res.data.msg);
                        this.isLoading = false;
                    } else {
                        this.showSnackbar("#b71c1c", "Error While Synchronization");
                        this.isLoading = false;
                    }
                })
                .catch((error) => {
                    // window.console.log(error);
                    this.isLoading = false;
                });
        },

        save() {
            if(this.Program_Dynamic.isRequired){
                if (this.savedata.program == null && this.savedata.program == "") {
                    this.showSnackbar("#b71c1c", "Select Program");
                    return;
                }
            }

            if(this.Year_Dynamic.isRequired){
                if (this.savedata.year == null) {
                    this.showSnackbar("#b71c1c", "Select Year");
                    return;
                }
            } 

            if(this.Academic_Year_Dynamic.isRequired){
                if (this.savedata.ay == null) {
                    this.showSnackbar("#b71c1c", "Select Academic Year");
                    return;
                } 
            } 
            
            if(this.Sem_Dynamic.isRequired){
                if (this.savedata.sem == null) {
                    this.showSnackbar("#b71c1c", "Select Semester");
                    return;
                }  
            }
            
            if(this.CPI_Dynamic.isRequired){
                if (this.savedata.percentage_cpi == null || this.savedata.percentage_cpi == 0) {
                    this.showSnackbar("#b71c1c", "Enter CPI Marks..");
                    return;
                }
            }

            if(this.SPI_Dynamic.isRequired){
                if (this.savedata.spi_marks == null || this.savedata.spi_marks == 0) {
                    this.showSnackbar("#b71c1c", "Enter SPI Marks..");
                    return;
                }  
            }
            
            if(this.No_Of_Dead_Backlog_Dynamic.isRequired){
                if (this.savedata.no_of_deadbacklog == null) {
                    this.showSnackbar("#b71c1c", "Enter No. of Dead backlog if any");
                    return;
                } 
            }

            if(this.No_Of_Active_Backlog_Dynamic.isRequired){
                if (this.savedata.islivebacklog && !this.savedata.no_of_activebacklog) {
                    this.showSnackbar("#b71c1c", "Enter No. of Active backlog");
                    return;
                } 
            }

            if(this.Backlog_Name_Dynamic.isRequired){
                if (this.savedata.backlog_name==null) {
                    this.showSnackbar("#b71c1c", "Enter Backlog Name");
                    return;
                } 
            }

            if (this.savedata.isackowledge == null) {
                this.showSnackbar("#b71c1c", "Please check ackowledgement checkbox");
                return;
            }

                this.dialog_add = false;
                axios
                    .post("/LearnerData/saveCurrentAcademics", this.savedata)
                    .then((res) => {
                        if (res.data.msg == "200") {
                            this.onLoad();
                            this.showSnackbar("#4caf50", "Academics Add Successfully..."); // show snackbar on success
                            this.savedata.program = null;
                            this.savedata.percentage_cpi = null;
                            this.savedata.spi_marks = null;
                            this.savedata.no_of_activebacklog = null;
                            this.savedata.no_of_deadbacklog = null;
                            this.savedata.pass_class = null;
                            this.savedata.pass_year = null;
                            this.savedata.is_compl = null;
                            this.savedata.isackowledge = null;
                            this.savedata.islivebacklog = null;
                        } else {
                            this.showSnackbar("#b71c1c", res.data.msg);
                            this.savedata.program = null;
                            this.savedata.percentage_cpi = null;
                            this.savedata.spi_marks = null;
                            this.savedata.no_of_activebacklog = null;
                            this.savedata.no_of_deadbacklog = null;
                            this.savedata.pass_class = null;
                            this.savedata.pass_year = null;
                            this.savedata.is_compl = null;
                            this.savedata.isackowledge = null;
                            this.savedata.islivebacklog = null;
                        }
                    })
                    .catch((error) => {
                        // window.console.log(error);
                    });

                this.close();
            
        }, // end of Save()

        edit() {
           
            if(this.Program_Dynamic.isRequired){
                if (this.editedItem.program == null && this.editedItem.program == "") {
                    this.showSnackbar("#b71c1c", "Select Program");
                    return;
                }
            }

            if(this.Year_Dynamic.isRequired){
                if (this.editedItem.year == null) {
                    this.showSnackbar("#b71c1c", "Select Year");
                    return;
                }
            } 

            if(this.Academic_Year_Dynamic.isRequired){
                if (this.editedItem.ay == null) {
                    this.showSnackbar("#b71c1c", "Select Academic Year");
                    return;
                } 
            } 
            
            if(this.Sem_Dynamic.isRequired){
                if (this.editedItem.sem == null) {
                    this.showSnackbar("#b71c1c", "Select Semester");
                    return;
                }  
            }
            
            if(this.CPI_Dynamic.isRequired){
                if (this.editedItem.percentage_cpi == null || this.editedItem.percentage_cpi == 0) {
                    this.showSnackbar("#b71c1c", "Enter CPI Marks..");
                    return;
                }
            }

            if(this.SPI_Dynamic.isRequired){
                if (this.editedItem.spi_marks == null || this.editedItem.spi_marks == 0) {
                    this.showSnackbar("#b71c1c", "Enter SPI Marks..");
                    return;
                }  
            }
            
            if(this.No_Of_Dead_Backlog_Dynamic.isRequired){
                if (this.editedItem.no_of_deadbacklog == null) {
                    this.showSnackbar("#b71c1c", "Enter No. of Dead backlog if any");
                    return;
                } 
            }

            if(this.No_Of_Active_Backlog_Dynamic.isRequired){
                if (this.editedItem.islivebacklog && !this.editedItem.no_of_activebacklog) {
                    this.showSnackbar("#b71c1c", "Enter No. of Active backlog");
                    return;
                } 
            }

            if(this.Backlog_Name_Dynamic.isRequired){
                if (this.editedItem.backlog_name==null) {
                    this.showSnackbar("#b71c1c", "Enter Backlog Name");
                    return;
                } 
            }

           
            if (this.editedItem.isackowledge == null) {
                this.showSnackbar("#b71c1c", "Please check ackowledgement checkbox");
                return;
            }

                this.dialog_edit = false;
                axios
                    .post("/LearnerData/editCurrentAcademics", this.editedItem)
                    .then((res) => {
                        if (res.data.msg == "200") {
                            this.dialog_edit = false;
                            this.showSnackbar("#4caf50", "Update Successfully..."); // show snackbar on success
                            this.onLoad();
                        } else {
                            this.editAllData.splice(this.editAllData);
                            this.showSnackbar("#b71c1c", "Record Not Found!!!");
                        }
                    })
                    .catch((error) => {
                        //window.console.log(error);
                    });
                this.close();
        }, // end of edit()
        delId(item) {
            this.dialog_delete = true;
            this.deleteItem.academic_id = item;
        },
        deletedata(item) {
            this.dialog_delete = false;
            axios
                .post("/LearnerData/deleteCurrentAcademics", this.deleteItem)
                .then((res) => {
                    if (res.data.msg == "200") {
                        if ((this.load = true)) {
                            this.onLoad();
                            this.showSnackbar("#4caf50", " Record Delete Successfully...");
                        }
                    } else if (res.data.msg == "2001") {
                        this.showSnackbar("#b71c1c", "Can't Delete Examination Details, already refered in Learner Profile");
                    } else {
                        this.showSnackbar("#b71c1c", "Record Not Delete...");
                    }
                });
        }, //end,

        isOnchangeCurrentCPI() {
            const data = {
                selected_cgpa: this.selected_cgpa,
            };
            axios
                .post("/LearnerData/isOnchangeCurrentCPI", data)
                .then((res) => {
                    if (res.data.msg == "200") {
                        this.showSnackbar("#4caf50", res.data.message);
                        this.onload();
                    } else {
                        this.showSnackbar("#b71c1c", res.data.msg);
                    }
                })
                .catch((error) => {
                    window.console.log(error);
                });
            this.close();
        },


    },
    watch: {
        isdatafetched() {
            //window.console.log("ISDATAfetch"+this.isdatafetched)
            if (this.isdatafetched == true) {
                this.onLoad();
                this.isdatafetched = false;
            }
        },

    }
};
</script>
<style scoped>
.nav-arrow-left {
    margin-right: 5px;
    text-decoration: none;
    color: #fff;
}

.nav-arrow-right {
    margin: 5px;
    text-decoration: none;
    color: rgb(3, 55, 167);
}

.left-arrow {
    color: #fff;
}

.right-arrow {
    color: rgb(3, 55, 167);
}

.ec-title {
    background-image: linear-gradient(-100deg, #f4faba, #d64907);
    color: #fff;
}

.c-title {
    background-image: linear-gradient(-90deg, #befcf7, #03948d);
    color: #fff;
}

.tc-title {
    background-image: linear-gradient(-90deg, #fff, #057996);
    color: #fff;
    border-radius: 3px;
}

.logi {
    background-image: linear-gradient(-100deg, #8aa5f7, #063bdb);
    color: #fff;
}

.card-title {
    background-image: linear-gradient(-90deg, #8edffd, #034c70);
    color: #fff;
}

.del-card-title {
    background-image: linear-gradient(-90deg, #fde7e7, #ac0404);
    color: #fff;
}

.lbl {
    color: #1705e6;
}

.v-text-field {
    padding-top: 0px;
    margin-top: 0px;
}

.alink {
    text-decoration: none;
}

.v-icon-size {
    font-size: 20px;
}

.edit-avatar {
    transition: 0.3s;
    border: 1px solid #b0bec5;
}

.edit-avatar:hover {
    background-color: #b0bec5;
}

.edit-v-icon {
    padding-left: 6px;
    font-size: 15px;
}

.edit-v-icon:hover {
    color: white;
}

.required {
    color: red;
    font-size: 14px;
    font-style: bold;
}

.blink {
    animation: blinker 0.77s linear infinite;
    color: red;
    font-family: sans-serif;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}
</style>